<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->

<!-- <header></header>
<navbar *ngIf="isAuthenticated()"></navbar>
<breadcrumbs *ngIf="isAuthenticated()"></breadcrumbs>
<div class="main-content">
    <router-outlet></router-outlet>
</div> -->
<ng-container *ngIf="isAuthenticated()">
    <div class="side-menu" *ngIf="menuOpen" @slideRight>
        <side-menu [class.reduce-height]="consoleComponenent && consoleComponenent.open ? true : false">
            <div *ngIf="!treeService.treeOpened && treeService.treeAvailable" class="tree-open-button" (click)="toggleTree(true)">
                <i class="fa-solid fa-folder-tree"></i>
                <!-- <i *ngIf="!treeOpened" class="fas fa-chevron-right"></i> -->
            </div>
        </side-menu>
    </div>
    <div class="main-content"
            [class.reduce-height]="consoleComponenent && consoleComponenent.open && consoleComponenent.deployed ? true : false"
            [class.open]="menuOpen" [@slideMarginLeft]="menuOpen ? 'margin' : 'nomargin'">
        <div *ngIf="menuOpen" class="close-button" (click)="toggleMenu(false)"><i class="fas fa-chevron-left"></i></div>
        <div *ngIf="!menuOpen" class="open-button" (click)="toggleMenu(true)"><i class="fas fa-chevron-right"></i></div>
        <breadcrumbs *ngIf="isAuthenticated()"></breadcrumbs>
        <div class="lower-content">
            <div class="tree-frame" *ngIf="treeService.treeOpened && treeService.studyNode && treeService.treeAvailable">
                <div class="tree-close-button" (click)="toggleTree(false)">
                    <i class="fas fa-chevron-left"></i>
                </div>
                <study-tree>
                </study-tree>
            </div>
            <div class="content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <shanoir-console #console></shanoir-console>
</ng-container>

<ng-container *ngIf="!isAuthenticated()">
    <div>
        <router-outlet></router-outlet>
    </div>
</ng-container>

<msg-box></msg-box>
<loader></loader>
