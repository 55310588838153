<!--
Shanoir NG - Import, manage and share neuroimaging data
Copyright (C) 2009-2019 Inria - https://www.inria.fr/
Contact us on https://project.inria.fr/shanoir/

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

You should have received a copy of the GNU General Public License
along with this program. If not, see https://www.gnu.org/licenses/gpl-3.0.html
-->
<div class="commands">
    <button class="right-icon dl-button" type="button" (click)="downloadSelected()" [disabled]="!(selectedDatasetNodes?.length > 0)">Download selection<i class="fas fa-download"></i></button>
    <button class="right-icon dl-button" type="button" (click)="goToProcessing()" [disabled]="!(selectedDatasetNodes?.length > 0)">Process selection<i class="fas fa-rocket"></i></button>
    <button class="right-icon dl-button" type="button" (click)="openInViewer()" [disabled]="!canOpenDicom" [title]="'Select examinations or acquisition from one exam to compare DICOM files in viewer'">View DICOM<i class="fas fa-eye"></i></button>
</div>

<study-node *ngIf="treeService.studyNode"
		[input]="treeService.studyNode"
		[hasBox]="true"
		[withMenu]="false"
		(nodeInit)="treeService.nodeInit = true"
		(selectedChange)="onSelectedChange($event)">
</study-node>

<ng-content></ng-content>
